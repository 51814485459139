<template>
    <div>
        <a-modal v-model="visible" title="编辑审批流程" :width="800" @ok="doCreate" @cancel="close" centered destroyOnClose>
            <a-form-model ref="formRef" :model="formData" :rules="formRule">
                <a-row :gutter="10">
                    <a-col :span="getOrganId() < 1 ? 8 : 16">
                        <a-form-model-item has-feedback prop="name" required label="流程名称">
                            <a-input v-model="formData.name" placeholder="请输入流程名称"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col span="8" v-if="getOrganId() == 0">
                        <a-form-model-item prop="organId" required label="所属单位">
                            <a-select v-model="formData.organId" style="width:100%" placeholder="请选择所属单位">
                                <a-select-option :value="organ.organId" v-for="(organ, index) in getOrganList()"
                                                 :key="index">{{
                                                     organ.name }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item prop="categoryId" required label="流程分类">
                            <a-select v-model="formData.categoryId">
                                <a-select-option :value="item.categoryId" v-for="item in category" :key="item.categoryId">{{
                                    item.name }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="10">
                    <a-col :span="8">
                        <a-form-model-item prop="formId" label="使用表单">
                            <a-select show-search has-feedback v-model="formData.formId" placeholder="请选择使用的表单"
                                      style="width: 100%" @search="searchForm" :filter-option="false">
                                <a-select-option :value="item.formId" v-for="(item, index) in formList" :key="index">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <!-- <a-col :span="8">
                        <a-form-model-item prop="entrust" label="委托类型">
                            <a-select v-model="formData.entrust">
                                <a-select-option value="按步骤设置的经办权限委托">按步骤设置的经办权限委托</a-select-option>
                                <a-select-option value="禁止委托">禁止委托</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col> -->
                    <a-col :span="4">
                        <a-form-model-item prop="isAttach" label="允许附件">
                            <a-switch v-model="formData.isAttach"></a-switch>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-model-item prop="isAttach" label="发起流程强制选择审批人">
                            <a-switch v-model="formData.isStaff"></a-switch>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-row :gutter="10">
                    <a-col :span="8">
                        <a-form-model-item prop="proofNumber" label="文号表达式">
                            <a-input v-model="formData.proofNumber">
                                <a-popover slot="suffix">
                                    <div slot="content">
                                        表达式中可以使用以下特殊标记：<br>
                                        {Y}：表示年 4位的年份 <br>
                                        {yy}：表示年 不包含纪元的年份，如果不包含纪元的年份小于 10，则显示具有前导0的年份<br>
                                        {M}：表示月 一位数的月份没有前导0<br>
                                        {mm}：表示月 一位数的月份有一个前导0<br>
                                        {D}：月中的某一天,一位数的日期没有前导0<br>
                                        {dd}：月中的某一天,一位数的日期有一个前导0<br>
                                        {H}：表示时<br>
                                        {I}：表示分<br>
                                        {S}：表示秒<br>
                                        {F}：表示流程名<br>
                                        {U}：表示用户姓名<br>
                                        {SD}：表示部门<br>
                                        {R}：表示角色<br>
                                        {N}：表示编号，通过 编号计数器 取值并自动增加计数值<br>
                                        例如，表达式为：成建委发[{Y}]{N}号，编号位数为4 <br>
                                        自动生成文号如：成建委发[2006]0001号<br>
                                        例如，表达式为：BH{N}，编号位数为3<br>
                                        自动生成文号如：BH001<br>
                                        例如，表达式为：{F}流程（{Y}年{M}月{D}日{H}:{I}）{U}<br>
                                        自动生成文号如：请假流程（2009年1月1日10:30）张三<br>
                                        可以不填写自动文号表达式，则系统默认按以下格式，如：<br>
                                        请假流程(2009-01-01 10:30)
                                    </div>
                                    <a-icon type="question-circle"></a-icon>
                                </a-popover>
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-model-item prop="proofIndex" label="自动编号计数">
                            <a-input-number v-model="formData.proofIndex" :min="1"></a-input-number>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-model-item prop="proofNumberLength" label="自动编号长度">
                            <a-input-number v-model="formData.proofNumberLength" :max="6" :min="1"></a-input-number>
                        </a-form-model-item>
                    </a-col>
                    <!-- <a-col :span="4">
                        <a-form-model-item prop="isProofInput" label="允许手工输入文号">
                            <a-switch v-model="formData.isProofInput"></a-switch>
                        </a-form-model-item>
                    </a-col> -->
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-model-item prop="description" label="流程说明">
                            <a-textarea v-model="formData.description"></a-textarea>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-modal>

    </div>
</template>

<script>
import apiUtils from '@/common/apiUtil';
import utils from '@/common/utils';
import request from '@/config/request';
export default {
    data() {
        return {
            visible: false,
            showProofTip: false,
            formList: [],
            formData: {
                approveId: '',
                categoryId: '',
                description: "",
                entrust: "按步骤设置的经办权限委托",//禁止委托
                formId: '',
                isAttach: true,
                isStaff: false,
                isProofInput: false,
                name: "",
                organId: this.organId,
                proofIndex: 0,
                proofNumber: "",
                proofNumberLength: 1,
                scopeDepIds: [],
                scopeRoleIds: [],
                scopeStaffIds: [],
                scopeStoreIds: []
            },
            formRule: {
                name: [{ required: true, message: '请输入流程名称', trigger: 'blur' }],
                categoryId: [{ required: true, message: '请选择流程分类' }],
                formId: [{ required: true, message: '请选择关联表单' }]
            }
        };
    },
    methods: {
        ...apiUtils,
        doCreate() {
            this.$refs['formRef'].validate((valid) => {
                if (valid) {
                    utils.showSpin();
                    request.post('/platform/approve/save', this.formData).then(ret => {
                        // console.log(ret);
                        this.close();
                        this.$emit('success', ret.data);
                    });
                }
            });
        },
        // 表单数据
        searchForm(keyword) {
            const param = { page: 1, limit: 30, keyword: keyword ? keyword : '' };
            request.get("/platform/approve/form/list", param).then(ret => {
                this.formList = ret.data.records;
            });
        },
        show(form) {
            this.formData.approveId = '';
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.formRef.resetFields();
                if (form) {
                    this.formData = JSON.parse(JSON.stringify(form));
                }
                this.searchForm();
            });
        },
        close() {
            this.$refs.formRef.resetFields();
            this.visible = false;
        }
    },
    props: {
        category: {
            type: Array,
            default: () => {
                return [];
            }
        },
        organId: {
            type: Number | String,
            default: () => {
                return apiUtils.getDefaultOrganId();
            }
        },
        categoryId: {
            type: Number | String,
            default: () => {
                return '';
            }
        }
    },
    watch: {
        organId: {
            handler(v) {
                this.formData.organId = v;
            }
        },
        categoryId: {
            handler(v) {
                console.log(v);

                this.formData.categoryId = v;
            }
        }
    }

};
</script>

<style></style>